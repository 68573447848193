@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : common.css
 Style : common
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Kosugi+Maru&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:200,300,400,500,600,700,900&display=swap&subset=japanese");
@media screen and (max-width: 468px) {
  img.imgL {
    max-width: 100%;
  }
}

header, footer {
  min-width: auto !important;
}

@media screen and (max-width: 768px) {
  .kaigyou {
    display: block;
  }
}

.resdis {
  display: none !important;
}

@media screen and (max-width: 834px) {
  .resdis {
    display: flex !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 14px !important;
  line-height: 1.3em;
  overflow: visible !important;
}

body {
  font-weight: 400;
  letter-spacing: 0.6;
  color: #333333;
  font-family: "Noto Sans JP", sans-serif, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  word-wrap: break-word;
  background-position: left center;
  overflow-x: hidden;
  background: #fff;
}

a {
  color: #1111cc !important;
  text-decoration: none;
}

.inner {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    max-width: 100%;
  }
}

#main, #main #col_main {
  width: 100% !important;
  max-width: 100% !important;
  float: none !important;
}

#contents_wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  min-width: auto !important;
}

@media screen and (max-width: 834px) {
  #contents_wrap {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 960px) {
  #contents_wrap {
    width: 100% !important;
    min-width: 100%;
  }
}

#contents {
  padding: 0;
  background: none !important;
  width: 100%;
}

@media screen and (max-width: 100%) {
  #main {
    width: 100%;
  }
}

@media screen and (max-width: 100%) {
  #main #col_main {
    width: 100%;
  }
}

@media screen and (max-width: 100%) {
  #side {
    width: 18.75000%;
  }
}

@media screen and (max-width: 100%) {
  #side aside {
    width: 100% !important;
  }
}

aside div#form_calendar {
  min-width: auto !important;
}

.blog #contents article .detail {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .blog #contents {
    padding: 0;
  }
  .blog #contents article {
    padding: 30px 10px 10px;
  }
  .blog #contents article .detail {
    margin-top: 20px;
  }
}

.map {
  margin: 56px auto 120px;
  padding: 60px 0;
}

@media screen and (max-width: 834px) {
  .map {
    width: 90%;
  }
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

@media screen and (max-width: 834px) {
  .map li {
    text-align: center;
  }
}

.map li:last-child {
  display: none;
}

.map li a {
  color: #333 !important;
}

.policy h2 {
  border-bottom: 1px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 5px;
}

.policy p {
  margin: 0 0 50px;
}

table {
  table-layout: fixed;
}

table td {
  margin-bottom: -1px !important;
  padding: 20px !important;
}

.tlauto table {
  table-layout: auto;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

@media screen and (max-width: 834px) {
  .youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

u {
  text-decoration: underline;
}

.borB {
  border-bottom: 1px dashed #333;
}

.b {
  font-weight: bold;
}

b {
  font-weight: bold;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a:hover {
  opacity: 0.6;
  transition: 0.7s;
}

.tacenter {
  text-align: center !important;
}

.ColLeft {
  float: left;
}

.ColRight {
  float: right;
}

.flexleft {
  display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
}

.flexcenter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.mincho {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", sans-serif, "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.br {
  display: block;
}

.br_pc {
  display: block;
}

@media screen and (max-width: 768px) {
  .br_pc {
    display: inline;
  }
}

.br_sp {
  display: inline;
}

@media screen and (max-width: 768px) {
  .br_sp {
    display: block;
  }
}

.br_tab {
  display: inline;
}

@media screen and (max-width: 834px) {
  .br_tab {
    display: block;
  }
}

/*********PANKUZU *********/
/* table td padding */
.tdpd0 td {
  padding: 0 !important;
}

.tdpd05 td {
  padding: 5px !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.tdpd15 td {
  padding: 15px !important;
}

.tdpd20 td {
  padding: 20px !important;
}

.tdpd25 td {
  padding: 25px !important;
}

.tdpd30 td {
  padding: 30px !important;
}

.tdpd35 td {
  padding: 35px !important;
}

.tdpd40 td {
  padding: 40px !important;
}

.tdpd45 td {
  padding: 45px !important;
}

.tdpd50 td {
  padding: 50px !important;
}

td.pdT10 {
  padding-top: 10px !important;
}

/* padding */
.pd0 {
  padding: 0px !important;
}

.pd10 {
  padding: 10px !important;
}

/********* Google Map &youtube *********/
/* small.css */
/* googlemap for single.php */
iframe[src^="https://www.google.com/maps/"] {
  width: 100%;
  height: 100%;
}

.ggmap {
  position: relative;
  padding-bottom: 26.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .ggmap {
    padding-bottom: 55%;
  }
}

.ggmap iframe,
.ggmap object,
.ggmap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 834px) {
  .youtube iframe {
    width: 100%;
  }
}

#builingual {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 6999999;
  padding: 5px 20px !important;
}

@media screen and (max-width: 768px) {
  #builingual {
    width: 100%;
  }
}

.switch {
  float: right;
  padding-top: 3px !important;
  position: relative;
}

.switch:after {
  content: "OFF";
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 8px;
  z-index: 1;
}

.switch:before {
  content: "ON";
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 8px;
  z-index: 1;
}

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}

input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}

input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: 0.4s;
}

input.cmn-toggle-round + label:after {
  width: 28px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.4s;
  z-index: 2;
}

input.cmn-toggle-round:checked + label:before {
  background-color: #8ce196;
}

input.cmn-toggle-round:checked + label:after {
  margin-left: 28px;
}

@media only screen and (max-width: 768px) {
  .switch {
    float: right;
    order: 1;
    padding-top: 0px !important;
  }
  #builingual {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/********* Other *********/
.header {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff !important;
}

@media screen and (max-width: 834px) {
  .header {
    padding-top: 44px;
  }
}

@media screen and (max-width: 834px) {
  .toplogo img {
    height: 40px !important;
    margin-left: 20px;
  }
}

.hdright {
  display: flex;
  align-items: center;
}

.hdright > div {
  margin-left: 20px;
}

@media screen and (max-width: 834px) {
  .hdright > div {
    margin-left: 0;
  }
}

.hdright > div p {
  font-size: 18px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
  letter-spacing: 0.1rem;
}

.hdright > div p a {
  color: #333 !important;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
}

.hdright .cont a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6087bb;
  padding: 10px;
  color: #fff !important;
  font-size: 20px;
  line-height: 1;
  width: 53px;
  height: 53px;
  border-radius: 10px;
}

@media screen and (max-width: 834px) {
  .hdright .cont a {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  .hdtext, .hdshop, .hdright p {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .inst, .face {
    margin-right: 10px;
  }
}

@media screen and (max-width: 834px) {
  .inst img, .face img {
    height: 40px !important;
  }
}

.hdnav {
  background-image: url(../images/home/navvg.jpg);
}

.hdul {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-around;
}

@media screen and (max-width: 834px) {
  .hdul {
    display: block;
    height: 100%;
  }
}

.hdul > li {
  height: 50px;
  width: 160px;
  border-right: 1px solid #333333;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

@media screen and (max-width: 834px) {
  .hdul > li {
    display: block;
    height: auto;
    width: 100%;
  }
}

.hdul > li:first-child {
  border-left: 1px solid #333;
}

@media screen and (max-width: 834px) {
  .hdul > li:first-child {
    border-left: none;
  }
}

.hdul > li a {
  color: #333 !important;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
  font-weight: bold;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bropnav {
  width: 160px;
  position: absolute;
  left: -10px;
  right: 0px;
  top: 50px;
  z-index: 9999;
  display: none;
}

@media screen and (max-width: 834px) {
  .bropnav {
    border-top: 1px solid #6087bb;
    position: static;
  }
}

.bropnav li {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/home/navvg.jpg);
}

@media screen and (max-width: 834px) {
  .bropnav li {
    background: #f7f7f7;
  }
}

.bropnav li a {
  color: #333 !important;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
  font-weight: bold;
  opacity: 1 !important;
}

.bropnav li a:hover {
  opacity: 0.7 !important;
}

.resdis {
  display: none;
}

@media screen and (max-width: 834px) {
  .resdis {
    display: block;
  }
}

.mean-bar {
  background-color: #6087bb !important;
  padding: 0px !important;
  position: fixed !important;
}

@media screen and (max-width: 834px) {
  .mean-bar li {
    border-top: 1px solid #6087bb !important;
  }
}

@media screen and (max-width: 834px) {
  .bropnav a {
    width: 100% !important;
    padding: 0px !important;
  }
}

.mean-nav {
  background: #fff !important;
  height: 100% !important;
}

.mean-nav ul li a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: black !important;
  line-height: 40px;
}

.meanmenu-reveal {
  color: #fff !important;
  width: 20px !important;
  height: 20px !important;
}

.mean-expand {
  border-left: 1px solid #6087bb !important;
  margin-top: 0px !important;
}

#footer2 {
  margin-top: 50px;
  color: #333;
  background: url(../images/common/cont_bg.jpg) center top no-repeat;
  background-size: cover;
  padding: 70px 10px;
}

@media only screen and (max-width: 1200px) {
  #footer2 {
    padding: 50px 10px;
  }
}

#footer2 .inner {
  max-width: 960px;
}

#footer2 .inner p {
  margin-bottom: 30px;
  width: 100%;
  font-size: 16px;
}

#footer2 .inner .ttl {
  font-weight: 700;
  line-height: 1.4em;
  color: #222;
  font-size: 37px;
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner .ttl {
    font-size: 25px;
  }
}

#footer2 .inner {
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #footer2 .inner {
    display: block;
  }
}

#footer2 .inner dl {
  width: 48%;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 0;
}

@media screen and (max-width: 768px) {
  #footer2 .inner dl {
    width: 49%;
  }
}

@media screen and (max-width: 768px) {
  #footer2 .inner dl {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #footer2 .inner dl:nth-child(2) {
    margin-top: 30px;
  }
}

#footer2 .inner dl:nth-child(2) dt {
  background: #da5019;
}

#footer2 .inner dl dt {
  background: #6087bb;
  margin-bottom: 30px;
  line-height: 1.7em;
  display: block;
  width: 100%;
  font-weight: 700;
  color: #fff;
  padding: 20px 10px;
  font-size: 25px;
}

@media screen and (max-width: 768px) {
  #footer2 .inner dl dt {
    font-size: 16px;
  }
}

@media screen and (max-width: 468px) {
  #footer2 .inner dl dt {
    font-size: 20px;
  }
}

#footer2 .inner dl dd {
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer2 .inner dl dd a {
  width: 100%;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer2 .inner dl dd a img {
  opacity: 1 !important;
}

#footer2 .inner dl dd a:hover {
  opacity: 1 !important;
}

#footer {
  padding-top: 80px;
  background-color: #fff !important;
}

@media screen and (max-width: 834px) {
  #footer {
    padding-top: 30px;
  }
}

.ftlogo {
  margin-bottom: 30px;
}

address P {
  margin-bottom: 30px;
}

.ftnav {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 834px) {
  .ftnav {
    display: block;
    margin-bottom: 30px;
  }
}

.ftnav li {
  margin-right: 20px;
}

@media screen and (max-width: 834px) {
  .ftnav li {
    margin-right: 0px;
  }
}

.ftnav li a {
  color: #333333 !important;
}

@media screen and (max-width: 834px) {
  .ftnav li a {
    width: 80%;
    margin: 0px auto 10px auto;
    line-height: 50px;
    background-color: #6087bb;
    color: #fff !important;
    display: block !important;
  }
}

.copy {
  background-color: #6087bb;
}

.copy small {
  color: #fff;
  line-height: 50px;
  font-size: 14px;
}

.topbt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.toppic {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.toppic img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.home_01 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media screen and (max-width: 834px) {
  .home_01 {
    padding: 30px 0 !important;
  }
}

.home_01 .home_title h1 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 26px;
  color: #6087bb;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .home_01 .home_title h1 {
    font-size: 20px;
  }
}

.home_01flex {
  display: flex;
}

@media screen and (max-width: 834px) {
  .home_01flex {
    flex-direction: column-reverse !important;
  }
}

.home_01flex > .box {
  width: 50%;
  font-size: 16px;
}

@media screen and (max-width: 834px) {
  .home_01flex > .box {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
  }
}

.home_01flex > .box a {
  width: 50%;
  margin-top: 30px;
  display: block !important;
  text-align: center;
  line-height: 50px;
  color: #6087bb !important;
  border: solid 1px #6087bb !important;
}

@media screen and (max-width: 834px) {
  .home_01flex > .box a {
    margin-left: auto;
    margin-right: auto;
  }
}

.home_01flex > .box a:hover {
  color: #fff !important;
  background-color: #6087bb;
  opacity: 1 !important;
}

.home_01flex > .box img {
  width: 100% !important;
  height: auto;
}

.home_01flex > .box:first-child {
  padding-right: 50px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .home_01flex > .box:first-child {
    padding-right: 0;
    margin-top: 20px;
  }
}

.home_02 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background-size: cover;
}

@media screen and (max-width: 834px) {
  .home_02 {
    padding: 30px 0 !important;
  }
}

.home_02title h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  position: relative;
}

.home_02title h2:after {
  content: url(../images/home/home_02bd.png);
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.home_blog {
  overflow-y: scroll;
  height: 250px;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
}

@media screen and (max-width: 834px) {
  .home_blog {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.home_blog .blog_text {
  padding: 15px 10px !important;
  display: flex;
  width: 100% !important;
}

.home_blog .blog_list {
  border: none !important;
  display: block !important;
}

.home_blog .blog_list > div {
  display: block !important;
  border-bottom: none !important;
}

.home_blog .detail {
  display: none;
}

.home_blog .blog_photo {
  display: none;
}

.home_blog .blog_date {
  color: #333 !important;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-right: 30px;
  width: 20%;
  padding-top: 2px;
}

.home_blog h3 {
  width: 80%;
  margin-bottom: 0px !important;
}

.home_blog h3 a {
  color: #333 !important;
  border-bottom: 1px solid #666;
}

.home_03 {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background-size: cover;
}

@media screen and (max-width: 834px) {
  .home_03 {
    padding: 30px 0 !important;
  }
}

.home_03title h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 24px;
  font-weight: bold;
  color: #6087bb;
}

.home_03flex {
  width: 100% !important;
}

@media screen and (max-width: 834px) {
  .slick-list {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.slick-list .box {
  text-align: center;
  margin: 20px 10px !important;
  background-color: #fff;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
}

@media screen and (min-width: 834px) {
  .slick-list .box {
    width: auto !important;
  }
}

.slick-list .box h3 {
  margin: 10px 0;
  font-weight: bold;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  color: #a1a326;
}

.slick-track {
  width: 8000px !important;
}

.slider {
  margin: 100px auto;
  width: 80%;
}

.slider img {
  height: 225px;
  width: auto;
  margin: 0 auto;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

/*slick setting*/
.slick-prev:before,
.slick-next:before {
  color: #6087bb !important;
  font-size: 50px !important;
}

.slick-prev {
  left: -60px !important;
}

@media screen and (max-width: 1024px) {
  .slick-prev {
    display: none !important;
  }
}

.slick-next {
  right: -60px !important;
}

@media screen and (max-width: 1024px) {
  .slick-next {
    display: none !important;
  }
}

.slick-dots li button::before {
  color: #6087bb !important;
}

.home_03bt a {
  width: 40%;
  margin: 0 auto;
  display: block !important;
  text-align: center;
  line-height: 50px;
  color: #6087bb !important;
  border: solid 1px #6087bb !important;
}

.home_03bt a:hover {
  color: #fff !important;
  background-color: #6087bb;
  opacity: 1 !important;
}

.home_04bg {
  background-repeat: repeat;
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.home_04 {
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media screen and (max-width: 834px) {
  .home_04 {
    display: block;
    padding: 30px 0;
  }
}

.home_04 > .box {
  width: 50%;
  font-size: 16px;
}

@media screen and (max-width: 834px) {
  .home_04 > .box {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
  }
}

.home_04 > .box img {
  width: 100% !important;
  height: 522px !important;
}

@media screen and (max-width: 834px) {
  .home_04 > .box img {
    height: auto !important;
  }
}

.home_04 > .box:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 834px) {
  .home_04 > .box:last-child {
    padding: 0px;
  }
}

.home_04 > .box:last-child .home_04bgin {
  background-repeat: no-repeat;
  width: 78%;
  margin: auto;
  padding: 50px;
  background-image: url(../images/home/home_04bg01.png);
}

@media screen and (max-width: 1060px) {
  .home_04 > .box:last-child .home_04bgin {
    padding: 50px 0px 50px 10px;
  }
}

@media screen and (max-width: 834px) {
  .home_04 > .box:last-child .home_04bgin {
    padding: 20px;
    width: 100%;
  }
}

.home_04 > .box:last-child h2 {
  text-align: center;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 24px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .home_04 > .box:last-child h2 {
    font-size: 18px;
  }
}

.home_04 > .box:last-child h2:after {
  content: "";
  background-size: cover;
  display: block;
  background-image: url(../images/home/home_041bd.png);
  width: 200px;
  height: 5px;
  position: absolute;
  bottom: -20px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.home_04 > .box:last-child a {
  width: 50%;
  margin-top: 30px;
  display: block !important;
  text-align: center;
  line-height: 50px;
  color: #6087bb !important;
  margin: 30px auto 0 auto;
  border: solid 1px #6087bb !important;
}

@media screen and (max-width: 834px) {
  .home_04 > .box:last-child a {
    margin-top: 10px;
  }
}

.home_04 > .box:last-child a:hover {
  color: #fff !important;
  background-color: #6087bb;
  opacity: 1 !important;
}

.sns_list {
  display: flex;
  border: none !important;
  flex-direction: inherit !important;
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

@media screen and (max-width: 834px) {
  .sns_list {
    flex-wrap: wrap;
  }
}

.sns_list > div {
  border: none !important;
}

@media screen and (max-width: 834px) {
  .sns_list > div {
    width: 33.3% !important;
    display: block !important;
  }
}

.sns_text {
  display: none;
}

.sns_photo {
  padding: 0px !important;
  width: 100% !important;
}

.home_insttitle h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  font-size: 24px;
  color: #6087bb;
}

.subtop {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background-size: cover;
  background-position: center;
}

.subtop h1 {
  line-height: 455px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
  color: #fff;
  font-size: 70px;
}

@media screen and (max-width: 834px) {
  .subtop h1 {
    line-height: 200px;
    font-size: 25px;
  }
}

.subbg {
  margin: 0;
  padding: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.pankuzu {
  margin-right: 10px;
}

.pankuzu a {
  color: #333 !important;
  border-bottom: 1px solid #333 !important;
}

.subtitile {
  padding: 30px 0 !important;
}

@media screen and (max-width: 834px) {
  .subtitile {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.subtitile h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
}

.subtitile h2:after {
  content: "";
  background-size: cover;
  display: block;
  background-image: url(../images/home/home_041bd.png);
  width: 200px;
  height: 5px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .subtitile h2:after {
    width: 100% !important;
  }
}

.subflex {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 834px) {
  .subflex {
    display: block;
    margin-bottom: 20px !important;
  }
}

.subflex > .box {
  width: 40%;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 834px) {
  .subflex > .box {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.subflex > .box > div {
  margin-top: 10px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

.subflex > .box img {
  width: auto !important;
  height: 288px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .subflex > .box img {
    height: auto !important;
    width: 100% !important;
  }
}

.kodawari_01flex {
  display: flex;
}

@media screen and (max-width: 834px) {
  .kodawari_01flex {
    flex-direction: column-reverse !important;
  }
}

.kodawari_01flex > .box {
  width: 50%;
  font-size: 16px;
}

@media screen and (max-width: 834px) {
  .kodawari_01flex > .box {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
  }
}

.kodawari_01flex > .box h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
}

.kodawari_01flex > .box h2:after {
  content: url(../images/home/home_041bd.png);
  background-size: cover;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.kodawari_01flex > .box img {
  width: 70% !important;
  height: 70% !important;
  box-shadow: 0 0 10px #333;
}

.kodawari_01flex > .box:first-child {
  padding-right: 50px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .kodawari_01flex > .box:first-child {
    padding-right: 0;
    margin-top: 20px;
  }
}

.kodawari_01flex > .box:last-child {
  position: relative;
  height: 360px;
}

@media screen and (max-width: 834px) {
  .kodawari_01flex > .box:last-child {
    height: 500px !important;
  }
}

@media screen and (max-width: 600px) {
  .kodawari_01flex > .box:last-child {
    height: 300px !important;
  }
}

.kodawari_02flex {
  display: flex;
}

@media screen and (max-width: 834px) {
  .kodawari_02flex {
    display: block;
  }
}

.kodawari_02flex > .box {
  width: 50%;
  font-size: 16px;
}

@media screen and (max-width: 834px) {
  .kodawari_02flex > .box {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
  }
}

.kodawari_02flex > .box h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
}

.kodawari_02flex > .box h2:after {
  content: url(../images/home/home_041bd.png);
  background-size: cover;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}

.kodawari_02flex > .box img {
  width: 70% !important;
  height: 70% !important;
  box-shadow: 0 0 10px #333;
}

.kodawari_02flex > .box:first-child {
  position: relative;
  height: 360px;
}

@media screen and (max-width: 834px) {
  .kodawari_02flex > .box:first-child {
    height: 500px !important;
  }
}

@media screen and (max-width: 600px) {
  .kodawari_02flex > .box:first-child {
    height: 300px !important;
  }
}

.kodawari_02flex > .box:last-child {
  padding-left: 50px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .kodawari_02flex > .box:last-child {
    padding-right: 0;
    margin-top: 20px;
    padding-left: 0px;
  }
}

.fleximg01 img {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.fleximg02 img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
}

.fleximg03 img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.fleximg04 img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
}

.btlist {
  border-bottom: 2px solid #6087bb;
}

@media screen and (max-width: 834px) {
  .btlist {
    border-bottom: none;
  }
}

@media screen and (max-width: 834px) {
  .btlist ul {
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 834px) {
  .btlist ul li {
    width: 30%;
  }
}

.btlist ul li button {
  background-image: url(../images/home/btbg01.png);
  color: #fff !important;
  border: none !important;
  margin-right: 5px;
  width: 120px;
  line-height: 40px;
  font-size: 16px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 834px) {
  .btlist ul li button {
    margin-right: 0px;
    width: 100%;
  }
}

.btlist ul li button:hover {
  opacity: 0.7;
  transition-duration: 1s;
}

.menu {
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .menu {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.menu > .box {
  width: 30%;
  box-shadow: 2px 10px 20px #ccc;
  text-align: center;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 10px;
}

@media screen and (max-width: 834px) {
  .menu > .box {
    width: 45%;
  }
}

.menu > .box img {
  width: auto !important;
  height: 191px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .menu > .box img {
    height: 100px !important;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.menu > .box h3 {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.menu > .box div {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.menu::after {
  content: "";
  width: 30%;
  display: block;
  height: 0px;
}

@media screen and (max-width: 834px) {
  .menu::after {
    width: 45%;
  }
}

@media screen and (max-width: 834px) {
  .menutitle {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 30px !important;
  }
}

.menutitle h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
  padding-left: 5px;
  position: relative;
}

.menutitle h2:after {
  content: "";
  background-size: cover;
  display: block;
  background-image: url(../images/home/home_041bd.png);
  width: 200px;
  height: 5px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  margin: 0 auto;
}

@media screen and (max-width: 834px) {
  .menutitle h2:after {
    width: 100% !important;
  }
}

.menu_04 {
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .menu_04 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.menu_04 > .box {
  width: 30%;
  box-shadow: 2px 10px 20px #ccc;
  text-align: center;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 10px;
}

@media screen and (max-width: 834px) {
  .menu_04 > .box {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.menu_04 > .box img {
  width: auto !important;
  height: 191px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .menu_04 > .box img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.menu_04 > .box h3 {
  margin: 10px 0;
  font-weight: bold;
  font-size: 18px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.menu_04 > .box div {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.menu_04::after {
  content: "";
  width: 30%;
  display: block;
  height: 0px;
}

@media screen and (max-width: 834px) {
  .menu_04::after {
    width: 45%;
  }
}

@media screen and (max-width: 834px) {
  .menu_05 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.menu_05 dl {
  display: block !important;
  margin-bottom: 20px;
}

.menu_05 dl dt {
  padding: 15px 15px 15px 15px !important;
  font-size: 18px;
  background-color: #6087bb !important;
  border: none !important;
  color: #fff;
  cursor: pointer;
  cursor: hand;
  position: relative;
}

.menu_05 dl dt::after {
  content: ">";
  display: block;
  position: absolute;
  top: 10px;
  right: 30px;
  color: #fff;
  font-size: 22px;
}

@media screen and (max-width: 834px) {
  .menu_05 dl dt::after {
    display: none;
  }
}

.menu_05 dl dd {
  padding: 0 0 0 15px !important;
  border: none !important;
  display: none;
  margin-top: 20px;
  font-size: 16px;
}

.menu_06 a {
  width: 50%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block !important;
  text-align: center;
  line-height: 50px;
  color: #6087bb !important;
  border: solid 1px #6087bb !important;
}

@media screen and (max-width: 834px) {
  .menu_06 a {
    width: 80%;
  }
}

.menu_06 a:hover {
  color: #fff !important;
  background-color: #6087bb;
  opacity: 1 !important;
}

.cook_pic {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.cook_02 {
  justify-content: space-around;
}

.cook_02 .box {
  width: 40%;
}

.cook_02 .box h3 {
  font-size: 18px;
  margin-bottom: 30px;
  border-bottom: 2px solid #6087bb;
  font-weight: bold;
  padding-left: 3px;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.cook_03 {
  border: 2px solid #6087bb;
  padding: 20px;
  position: relative;
}

@media screen and (max-width: 834px) {
  .cook_03 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.cook_03 h3 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .shop_01 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.shop_01 dt {
  padding: 20px !important;
  align-items: center !important;
  color: #333;
  background-color: rgba(66, 132, 255, 0.2) !important;
}

@media screen and (max-width: 834px) {
  .shop_01 dt {
    padding: 10px !important;
  }
}

.shop_01 dd {
  padding: 20px !important;
}

@media screen and (max-width: 834px) {
  .shop_01 dd {
    padding: 10px !important;
  }
}

.shop_01 dd a {
  color: #333 !important;
}

@media screen and (max-width: 834px) {
  .shopmap {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.shopmap iframe {
  height: 400px;
}

@media screen and (max-width: 834px) {
  .shopmap iframe {
    height: 200px;
  }
}

@media screen and (max-width: 834px) {
  .shop_02 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.shop_02 h3 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  border-bottom: 1px solid #6087bb;
  margin-bottom: 20px;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .shop_03 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.syousai_flex {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .syousai_flex {
    display: block;
  }
}

.syousai_flex .syousai_01 {
  width: 45%;
}

@media screen and (max-width: 834px) {
  .syousai_flex .syousai_01 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.syousai_flex .syousai_01 td {
  border: none !important;
  padding: 10px !important;
}

.syousai_flex .syousai_01 img {
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.syousai_flex .syousai_02 {
  width: 45%;
}

@media screen and (max-width: 834px) {
  .syousai_flex .syousai_02 {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.syousai_flex .syousai_02 h2 {
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 22px;
  padding-bottom: 5px;
  border-bottom: 1px solid #6087bb;
  margin-bottom: 30px;
  font-weight: bold;
}

.syousaibt {
  margin-top: 10px;
}

.syousaibt .onshop a, .syousaibt .shopfm a {
  width: 100%;
  margin-bottom: 10px;
  display: block !important;
  text-align: center;
  line-height: 50px;
  color: #6087bb !important;
  border: solid 1px #6087bb !important;
}

.syousaibt .onshop a:hover, .syousaibt .shopfm a:hover {
  color: #fff !important;
  background-color: #6087bb;
  opacity: 1 !important;
}

.blognv {
  position: static !important;
}

.blog #contents {
  margin-top: 30px;
  padding-left: 50px !important;
}

@media screen and (max-width: 834px) {
  .blog #contents {
    padding-left: 0px !important;
  }
}

.blog #contents .date {
  box-sizing: border-box;
  color: #6087bb;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px !important;
}

.blog #contents h2 {
  border-bottom: solid 2px #6087bb;
  padding-bottom: 10px !important;
  margin-bottom: 20px;
  padding-left: 3px;
  font-weight: bold;
}

.blog #contents img {
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 834px) {
  .blog .wrap {
    flex-direction: column-reverse !important;
  }
}

@media screen and (max-width: 834px) {
  .blog aside {
    width: 100% !important;
  }
}

.blog nav li {
  border-color: #333 !important;
}

.blog nav h3 {
  color: #333 !important;
  font-size: 18px;
}

.blog nav .pages {
  text-align: left;
}

.blog nav a {
  color: #333 !important;
}

.blogtel a {
  font-size: 18px !important;
}

@media screen and (max-width: 834px) {
  .text_404 {
    width: 90%;
    margin: 30px auto !important;
  }
}

.text_404 h2 {
  margin: 30px auto;
  display: table;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  font-size: 1.71429rem;
}

.bt_404 a {
  display: block;
  width: 200px;
  line-height: 50px;
  border: solid 1px #000;
  border-radius: 3px;
  color: #000 !important;
  margin: 30px auto;
}

.contact_01, .contact_02 {
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.tyuumonsyo {
  color: #a1a326 !important;
  border-bottom: 1px solid #a1a326;
  padding: 0 10px;
}

.tyuumonsyo .fa-file-export {
  margin-right: 5px;
}

@media screen and (min-width: 768px) {
  .telnolink {
    pointer-events: none;
  }
}

.konpoubt {
  position: relative;
  padding-top: 30px;
}

.konpoubt a {
  position: absolute;
  bottom: -75px;
  right: 0px;
  width: 250px;
  color: #fff !important;
  line-height: 40px;
  display: block;
  text-shadow: 0 0 10px #a1a326;
  text-align: center;
  font-size: 20px;
  background-image: url(../images/home/konpoubg.png);
  box-shadow: 0 0 10px #bbb;
  font-family: "Noto Serif JP", serif, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: bold;
}

@media screen and (max-width: 834px) {
  .konpoubt a {
    position: static !important;
    margin: 0 auto;
  }
}
